import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  overlayContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  subtitle: {
    fontWeight: 300,
    fontSize: 20,
    marginTop: 40,
    marginLeft: 32,
    color: `${theme.palette.primary.dark}`,
  },
}));

const Overlay = () => {
  const classes = useStyles();
  return (
    <div className={classes.overlayContainer}>
      <CircularProgress size="200px" />
      <Typography className={classes.subtitle} variant="overline">
        Cargando...
      </Typography>
    </div>
  );
};

export default Overlay;
