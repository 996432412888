import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getNotifications } from "../../../../redux/actions/room";
import { formatDateAndHour } from "../../../../utils/datetime";

const useStyles = makeStyles((theme) => ({
  container: (props) => ({
    display: "flex",
    flexDirection: "column",
    height: !props.esLayoutMobile ? "100%" : "50%",
    marginBottom: 5,
    justifyContent: props.esLayoutMobile ? "flex-end" : "space-between",
  }),
  welcomeText: (props) => ({
    fontSize: props.esLayoutMobile ? 14 : 18,
    margin: props.esLayoutMobile ? 10 : 15,
    textAlign: "center",
    backgroundColor: "#f2f0f2",
    padding: 10,
    borderRadius: 10,
    marginTop: props.esLayoutMobile ? 25 : 145,
    border: "2px solid #636262",
  }),
  card: (props) => ({
    backgroundColor: "#f2f0f2",
    width: "100%",
    height: "50%",
    borderRadius: 10,
    boxShadow: 2,
    marginTop: 15,
    marginBottom: props.esLayoutMobile ? 25 : 45,
  }),
  cardHeader: {
    backgroundColor: "rgba(42, 93, 247, 0.75)",
    borderRadius: "10px 10px 0px 0px",
    padding: 5,
  },
  cardHeaderText: (props) => ({
    color: "white",
    marginLeft: 20,
    fontSize: props.esLayoutMobile ? 14 : 16,
    fontWeight: "bold",
  }),
  cardContent: (props) => ({
    height: !props.esLayoutMobile ? "100%" : "90%",
    marginBottom: !props.esLayoutMobile ? 20 : 0,
    padding: 5,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  }),
  messagesContainer: (props) => ({
    backgroundColor: "rgba(255, 255, 255, 0.76)",
    border: "1px solid rgba(189, 182, 182, 0.27)",
    borderRadius: 10,
    padding: 5,
    marginBottom: !props.esLayoutMobile ? 15 : 5,
    height: "100%",
    overflow: "auto",
    maxHeight: "100%",
  }),
  messageBox: {
    backgroundColor: "#f2f0f2",
    borderRadius: 10,
    padding: 5,
    margin: 5,
    boxShadow: 1,
  },
  messageText: (props) => ({
    fontSize: props.esLayoutMobile ? 12.5 : 14,
  }),
  messageTime: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "10px",
    color: "#777",
  },
}));

const ProfesionalNotifications = () => {
  const esLayoutMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles({ esLayoutMobile });
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const idRoom = searchParams.get("id");

  const notifications = useSelector((state) => state.room.notifications);
  const {
    apellidoNombrePaciente,
    apellidoNombreProfesional,
    fechaHoraAgendada,
    duracionAgendada,
  } = useSelector((state) => state.call.call.data);

  useEffect(() => {
    dispatch(getNotifications(idRoom));
    const intervalId = setInterval(() => {
      dispatch(getNotifications(idRoom));
    }, 60000);
    return () => clearInterval(intervalId);
  }, [dispatch, idRoom]);

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.welcomeText}>
        Bienvenido/a! {apellidoNombrePaciente.toUpperCase()}. Tu cita está
        programada para el {formatDateAndHour(fechaHoraAgendada).fecha} a las{" "}
        {formatDateAndHour(fechaHoraAgendada).hora}. La duración aproximada es
        de {duracionAgendada} minutos. En breve el Dr/a.{" "}
        {apellidoNombreProfesional.toUpperCase()} se conectará...
      </Typography>
      <Card className={classes.card}>
        <Box className={classes.cardHeader}>
          <Typography variant="h6" className={classes.cardHeaderText}>
            Mensajes del Dr. {apellidoNombreProfesional}
          </Typography>
        </Box>
        <CardContent className={classes.cardContent}>
          <Box className={classes.messagesContainer}>
            {notifications.loading ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : notifications.data.length > 0 ? (
              notifications.data.map((msg) => (
                <Box key={msg.id} className={classes.messageBox}>
                  <Typography variant="body2" className={classes.messageText}>
                    {msg.mensaje}
                    <span className={classes.messageTime}>{msg.hora}</span>
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary" align="center">
                No hay notificaciones.
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProfesionalNotifications;
