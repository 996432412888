import {
  Box,
  Button,
  Card,
  CircularProgress,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ZoomVideo from "@zoom/videosdk";
import { useEffect, useRef, useState } from "react";

let localAudio = ZoomVideo.createLocalAudioTrack();
let allDevices;

const useStyles = makeStyles({
  preview_root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "absolute",
    bottom: 50,
    padding: 0,
  },
  testContainer: {
    borderRadius: 10,
    width: "70%",
  },
  testItem: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f2f0f2",
  },
  testButton: {
    width: "fit-content",
    fontSize: 12,
    backgroundColor: "rgba(88, 125, 235, 0.64)",
    cursor: "pointer",
  },
  progress: {
    margin: 10,
    backgroundColor: "#f2f0f2",
  },
  cardHeader: {
    backgroundColor: "rgba(42, 93, 247, 0.75)",
    borderRadius: "10px 10px 0px 0px",
    padding: 5,
  },
  cardHeaderText: (props) => ({
    color: "white",
    marginLeft: 20,
    fontSize: 16,
    fontWeight: "bold",
  }),
  firstTestContainer: {
    marginBottom: 10,
  },
});

const mountDevices = async () => {
  allDevices = await ZoomVideo.getDevices();
  const cameraDevices = allDevices.filter((device) => {
    return device.kind === "videoinput";
  });
  const micDevices = allDevices.filter((device) => {
    return device.kind === "audioinput";
  });
  const speakerDevices = allDevices.filter((device) => {
    return device.kind === "audiooutput";
  });
  return {
    mics: micDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    }),
    speakers: speakerDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    }),
    cameras: cameraDevices.map((item) => {
      return { label: item.label, deviceId: item.deviceId };
    }),
  };
};

const Preview = () => {
  const classes = useStyles();
  const [micList, setMicList] = useState([]);
  const [speakerList, setSpeakerList] = useState([]);
  const [activeMicrophone, setActiveMicrophone] = useState("");
  const [activeSpeaker, setActiveSpeaker] = useState("");
  const [outputLevel, setOutputLevel] = useState(0);
  const [inputLevel, setInputLevel] = useState(0);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [isRecordingVoice, setIsRecordingVoice] = useState(false);
  const [isPlayingRecording, setIsPlayingRecording] = useState(false);

  const speakerTesterRef = useRef();
  const microphoneTesterRef = useRef();
  const componenteEstaMontado = useRef(true);

  useEffect(() => {
    return () => {
      componenteEstaMontado.current = false;
    };
  }, []);

  useEffect(() => {
    mountDevices().then((devices) => {
      // Si no chequeamos que el componente esté montado, la
      // promesa puede resolverse después de que el componente se
      // desrenderice y eso lleva a un memory leak.
      if (componenteEstaMontado.current) {
        setMicList(devices.mics);
        setSpeakerList(devices.speakers);
        if (devices.speakers.length > 0) {
          setActiveSpeaker(devices.speakers[0].deviceId);
        }
        if (devices.mics.length > 0) {
          setActiveMicrophone(devices.mics[0].deviceId);
        }
      }
    });
  }, []);

  const onTestSpeakerClick = () => {
    if (microphoneTesterRef.current) {
      microphoneTesterRef.current.destroy();
      microphoneTesterRef.current = undefined;
    }
    if (isPlayingAudio) {
      speakerTesterRef.current?.stop();
      setIsPlayingAudio(false);
      setOutputLevel(0);
    } else {
      speakerTesterRef.current = localAudio.testSpeaker({
        speakerId: activeSpeaker,
        onAnalyseFrequency: (value) => {
          setOutputLevel(Math.min(100, value));
        },
      });
      setIsPlayingAudio(true);
    }
  };

  const onTestMicrophoneClick = () => {
    if (speakerTesterRef.current) {
      speakerTesterRef.current.destroy();
      speakerTesterRef.current = undefined;
    }
    if (!isPlayingRecording && !isRecordingVoice) {
      microphoneTesterRef.current = localAudio.testMicrophone({
        microphoneId: activeMicrophone,
        speakerId: activeSpeaker,
        recordAndPlay: true,
        onAnalyseFrequency: (value) => {
          setInputLevel(Math.min(100, value));
        },
        onStartRecording: () => {
          setIsRecordingVoice(true);
        },
        onStartPlayRecording: () => {
          setIsRecordingVoice(false);
          setIsPlayingRecording(true);
        },
        onStopPlayRecording: () => {
          setIsPlayingRecording(false);
        },
      });
    } else if (isRecordingVoice) {
      microphoneTesterRef.current?.stopRecording();
      setIsRecordingVoice(false);
    } else if (isPlayingRecording) {
      microphoneTesterRef.current?.stop();
      setIsPlayingRecording(false);
    }
  };

  let microphoneBtn = "Probar microfono";
  if (isRecordingVoice) {
    microphoneBtn = "Grabando";
  } else if (isPlayingRecording) {
    microphoneBtn = "Empezando";
  }

  return (
    <div className={classes.preview_root}>
      <Card
        className={`${classes.testContainer} ${classes.firstTestContainer}`}>
        <Box className={classes.cardHeader}>
          <Typography variant="h6" className={classes.cardHeaderText}>
            Prueba de altavoz
          </Typography>
        </Box>
        <div className={classes.testItem}>
          <Button
            color="secondary"
            variant="contained"
            className={classes.testButton}
            onClick={onTestSpeakerClick}>
            {isPlayingAudio ? "Parar prueba" : "Probar altavoz"}
          </Button>
          {speakerList.length ? (
            <Select
              disabled
              onChange={(value) => {
                setActiveSpeaker(value);
              }}
              value={activeSpeaker}>
              {speakerList.map((item) => {
                return (
                  <MenuItem value={item.deviceId} key={item.deviceId}>
                    {item.label.split("-")[1]}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <CircularProgress
              color="secondary"
              size="25px"
              style={{ margin: 5 }}
            />
          )}
          <div className={classes.progress}>
            <Typography>Nivel de salida</Typography>
            <LinearProgress
              value={outputLevel}
              variant="determinate"
              color="secondary"
            />
          </div>
        </div>
      </Card>
      <Card className={classes.testContainer}>
        <Box className={classes.cardHeader}>
          <Typography variant="h6" className={classes.cardHeaderText}>
            Prueba de microfono
          </Typography>
        </Box>
        <div className={classes.testItem}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onTestMicrophoneClick}
            className={classes.testButton}>
            {microphoneBtn}
          </Button>
          {micList.length ? (
            <Select
              disabled
              onChange={(value) => {
                setActiveMicrophone(value);
              }}
              value={activeMicrophone}>
              {micList.map((item) => {
                return (
                  <MenuItem value={item.deviceId} key={item.deviceId}>
                    {item.label.split("-")[1]}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            <CircularProgress
              color="secondary"
              size="25px"
              style={{ margin: 5 }}
            />
          )}
          <div className={classes.progress}>
            <Typography>Nivel de entrada</Typography>
            <LinearProgress
              value={inputLevel}
              variant="determinate"
              color="secondary"
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Preview;
