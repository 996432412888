import axios from "axios";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

export const getNotifications = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: axios
        .get(`sala/v1/mensajesProfesional?id=${encodeURIComponent(id)}`)
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response)),
    });
  };
};
