import { VideoQuality } from "@zoom/videosdk";

const ASPECT_RATIO = 16 / 9;
export const MIN_CELL_WIDTH = 364;
const MIN_CELL_HEIGHT = MIN_CELL_WIDTH / ASPECT_RATIO;

export function esNavegadorMobile() {
  if (
    typeof navigator === "undefined" ||
    typeof navigator.userAgent !== "string"
  ) {
    return false;
  }
  // NOTA: No es una buena idea depender del "User Agent" para
  // tomar una decisión sobre cómo renderizar nuestra página web. Referencia:
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
  // Aun así, nuestro producto toma decisiones en base a las capabilidades que
  // tienen los usuarios de compartir pantalla. Es por esto que no podemos
  // utilizar simplemente, mediaQueries.
  // Opera 12 utiliza el string "Mobi" dentro del "User Agent",
  // y los demás navegadores contienen "Mobile", por lo que es mejor
  // utilizar "Mobi" para el regex.
  return /Mobi/.test(navigator.userAgent);
}

export function getVideoLayout(
  anchoCanvas,
  alturaCanvas,
  cantidadParticipantes,
  deberiaEstarEnSalaEspera,
) {
  if (cantidadParticipantes === 0 || anchoCanvas === 0 || alturaCanvas === 0) {
    return [];
  }

  if (cantidadParticipantes === 1 && deberiaEstarEnSalaEspera) {
    // Caso especial: Solo hay un participante y es el paciente
    const isMobile = esNavegadorMobile();
    const patientVideoWidth = Math.min(anchoCanvas * 0.5, 500);
    // si no se divide por el ASPECT_RATIO, la etiqueta queda muy alta
    const patientVideoHeight = patientVideoWidth / ASPECT_RATIO;

    const patientVideo = {
      width: isMobile ? patientVideoWidth * 1.5 : patientVideoWidth,
      height: isMobile ? patientVideoHeight * 2 : patientVideoHeight,
      y: Math.min(
        (alturaCanvas - isMobile
          ? patientVideoHeight * 0.5
          : patientVideoHeight) / 1,
        500,
      ),
      x: (anchoCanvas - patientVideoWidth) / (isMobile ? 4 : 2),
      quality: VideoQuality.Video_360P,
    };
    return [patientVideo];
  }

  if (cantidadParticipantes === 1 && !deberiaEstarEnSalaEspera) {
    // Caso especial: Solo hay un participante y es el profesional,
    // ya que cuando solo está el paciente en llamada, por regla
    // de negocio, debe estar en la sala de espera
    const profesionalVideoWidth = anchoCanvas;
    const profesionalVideoHeight = esNavegadorMobile()
      ? profesionalVideoWidth / ASPECT_RATIO
      : alturaCanvas;

    const profesionalVideo = {
      width: profesionalVideoWidth,
      height: profesionalVideoHeight,
      y: (alturaCanvas - profesionalVideoHeight) / 2,
      x: 0,
      quality: VideoQuality.Video_1080P,
    };
    return [profesionalVideo];
  }

  // Tenemos que saber si el navegador es mobile,
  // ya que MIN_CELL_WIDTH es 256 y en mobile queda
  // corto el ancho del localVideoWidth.
  if (cantidadParticipantes === 2) {
    const remoteVideoWidth = anchoCanvas;
    const remoteVideoHeight = esNavegadorMobile()
      ? MIN_CELL_HEIGHT
      : alturaCanvas;

    const localVideoWidth = !esNavegadorMobile() ? MIN_CELL_WIDTH : anchoCanvas;
    const localVideoHeight = esNavegadorMobile()
      ? MIN_CELL_HEIGHT
      : localVideoWidth / ASPECT_RATIO;

    const remoteVideo = {
      width: remoteVideoWidth,
      height: remoteVideoHeight,
      y: (alturaCanvas - remoteVideoHeight) / 2,
      x: 0,
      quality: VideoQuality.Video_1080P,
    };

    const localVideo = {
      width: localVideoWidth,
      height: localVideoHeight,
      // este "y" luego se usa para posicionar el avatar del participante
      y: 0,
      x: anchoCanvas - localVideoWidth,
      // Que la calidad sea de 180p ya que el recuadro de video va a
      // ser demasiado chico como para notar una calidad de 1080p.
      quality: VideoQuality.Video_180P,
    };

    return [remoteVideo, localVideo];
  }
}
