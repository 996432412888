import { GET_NOTIFICATIONS } from "../actions/room";

const INITIAL_STATE = {
  notifications: {
    data: {},
    loading: false,
    error: false,
  },
};

const profesional = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_NOTIFICATIONS}_REJECTED`:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
          error: true,
          data: action.payload?.data,
        },
      };

    case `${GET_NOTIFICATIONS}_PENDING`:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: true,
          error: false,
        },
      };

    case `${GET_NOTIFICATIONS}_FULFILLED`:
      return {
        ...state,
        notifications: {
          data: action.payload,
          loading: false,
          error: false,
        },
      };

    default:
      return state;
  }
};

export default profesional;
