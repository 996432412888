const colors = {
  palette: {
    primary: {
      light: "#6ebaa5",
      main: "#2ebf91",
      dark: "#0E615D",
      grey: "#bdbbbb",
      errorGradient1: "#8e9eab",
      errorGradient2: "#eef2f3",
      homeGradient1: "rgba(223,240,228,1) 1%",
      homeGradient2: "rgba(25,118,210,1) 75%",
    },
    secondary: {
      light: "#96a8a3",
      main: "#1976d2",
      dark: "#76afa8",
    },
  },
};
export default colors;
